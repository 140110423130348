.icon-footer {
    width: 10vw;
    _border: solid 3px red;
    display: inline-block;
}


@media (min-width: 700px) {
  .icon-footer {
    width: 5vw;
  }
}

@media (min-width: 900px) {
  .icon-footer {
    width: 3vw;
    margin: 1vw 0.5vw 0 0.5vw;
  }
}

.footerFixed {
  position: fixed;
  bottom: 10px;
  left: 50%; /* Establece el punto de inicio del footer en el centro de la pantalla */
  transform: translateX(-50%); /* Mueve el footer hacia la izquierda basado en su propia anchura */
}