/* imágen de fondo Start*/
.container_back-offer {
    position: sticky;
    top:0;
    left: 0;
    width: 100vw;
    height: 0;
    z-index: -100;
}
.back-image-offer {
    _width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -100;
}
/* imágen de fondo End*/

.background-offer {
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centra verticalmente el contenido */
    align-items: center; /* Centra horizontalmente el contenido cuando es necesario */
    width: 70vw;
    max-height: 70vh;
    margin-right: auto;
    margin-left: auto;
    padding-top: 3vw;
    color: #fff;
    text-shadow: 1px 1px #000;    
    border: 3px solid #7fbbde;
    max-width: 400px; /* Asegúrate de que esto no restrinja demasiado el contenido */
    background-color: rgba(0, 0, 0, 0.5);
}


@media (min-width: 700px) {
    .back-image-offer{
        _width: 96vw;
        position:relative;
        margin: auto;
    }
}