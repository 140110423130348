/* imágen de fondo Start*/
.container_back-about-me {
    position: sticky;
    top:0;
    left: 0;
    width: 100vw;
    height: 0;
    z-index: -100;
}
.back-image-about-me {
    _width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -100;
}
/* imágen de fondo End*/

.background-about-me {
    width: 96vw;
    min-height: 80vh;
    _max-width: 800px ;
    margin-right: 2vw ;
    margin-left: 2vw;
    padding-top: 3vw;
    
    /* border: 3px solid greenyellow; */
}

.textShadow-about-me {
    color: #fff!important;
    _color:aliceblue!important;
    text-shadow: 1px 1px #000 !important;
    _background-color: blue;
}

@media (min-width: 840px) {
    .background-about-me{
        max-width: 840px;
        position:relative;
        margin: auto;
    }
}