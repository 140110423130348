.container_back{
    position: sticky;
    top:0;
    left: 0;
    width: 100vw;
    height: 0;
    z-index: -100;
}

.back-image-home{
    height: 100vh;
    position: fixed;
    top: 0;
    /* left: -500px;  */
    z-index: -100;
}


.background-home {
    _width: 100vw;
    min-height: 80vh;
    _margin: 0;
    _padding: 0;
    /* border: 3px solid greenyellow;  */
}



@media (min-width: 1100px) {
    .back-image{
        transform: translate(-50%,0%);
    }
  }
