.background-immage {
    background-image: url('/public/images/contact.jpg');
    /* background-color: aqua; */
    background-repeat: no-repeat;
    background-attachment: fixed;
    /* background-size:cover; */
    background-size:contain;
    /* background-position: center center; */
    background-position: center center;
    /* height: 80vh; */
    width: 100vw;
    height: 98vh;

    /* width: 100%; */
    /* height: auto; */
    
    margin: 0;
    padding-top: 3vw;
    
    /* border: 3px solid greenyellow; */
}

.textShadow {
    color: '#fff';
    text-shadow: '1px 1px #000';
}